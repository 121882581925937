import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Columns = makeShortcode("Columns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Columns mdxType="Columns">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2460ddf91c3938aa7258dc0204b20810/81b0c/hitabis-team.avif 160w", "/static/2460ddf91c3938aa7258dc0204b20810/f13a9/hitabis-team.avif 200w", "/static/2460ddf91c3938aa7258dc0204b20810/5f1e1/hitabis-team.avif 340w", "/static/2460ddf91c3938aa7258dc0204b20810/3c285/hitabis-team.avif 520w", "/static/2460ddf91c3938aa7258dc0204b20810/07e28/hitabis-team.avif 1200w", "/static/2460ddf91c3938aa7258dc0204b20810/67dcd/hitabis-team.avif 1592w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/2460ddf91c3938aa7258dc0204b20810/60a22/hitabis-team.webp 160w", "/static/2460ddf91c3938aa7258dc0204b20810/ba381/hitabis-team.webp 200w", "/static/2460ddf91c3938aa7258dc0204b20810/8ae5a/hitabis-team.webp 340w", "/static/2460ddf91c3938aa7258dc0204b20810/002e6/hitabis-team.webp 520w", "/static/2460ddf91c3938aa7258dc0204b20810/92f8c/hitabis-team.webp 1200w", "/static/2460ddf91c3938aa7258dc0204b20810/02aaf/hitabis-team.webp 1592w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2460ddf91c3938aa7258dc0204b20810/0913d/hitabis-team.jpg 160w", "/static/2460ddf91c3938aa7258dc0204b20810/e07e9/hitabis-team.jpg 200w", "/static/2460ddf91c3938aa7258dc0204b20810/d30a3/hitabis-team.jpg 340w", "/static/2460ddf91c3938aa7258dc0204b20810/ddb38/hitabis-team.jpg 520w", "/static/2460ddf91c3938aa7258dc0204b20810/e5166/hitabis-team.jpg 1200w", "/static/2460ddf91c3938aa7258dc0204b20810/9a46a/hitabis-team.jpg 1592w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2460ddf91c3938aa7258dc0204b20810/e5166/hitabis-team.jpg",
              "alt": "Hitabis Team",
              "title": "Hitabis Team",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p>{`Als inhabergeführtes IT-Unternehmen entwickeln wir bei Hitabis seit mehr als zehn Jahren IT-Lösungen nach Maß. Anfangs erstellten die Hitabis-Mitarbeiter vor allem individuelle datenbankbasierte Systemlösungen für Industriekunden. Schon bald wurde die Hitabis GmbH von weiteren namhaften Organisationen und Unternehmen mit der Konzeption und Entwicklung kundenspezifischer IT-Systeme beauftragt.`}</p>
      <p>{`Die Kompetenz der Hitabis GmbH gründet sich auf der umfassenden und langjährigen Erfahrungen ihrer Mitarbeiter auf dem Gebiet der Softwareentwicklung und des IT-Projekt- und Testmanagements. Dabei legen wir großen Wert auf eine permanente Aktualisierung und Weiterbildungen unserer Mitarbeiter. Dies erreichen wir durch regelmäßige Teilnahmen an Weiterbildungen, Workshops, Fachkongressen und -messen.`}</p>
      <p>{`Ein besonders kooperatives Miteinander, regelmäßiges Feedback und aktiv gestalteter Erfahrungsaustausch über alle Fachbereiche und Schwerpunkte hinweg prägen die Hitabis-interne Firmenkultur. Flache Hierarchien, hohe Flexibilität und gelebter Teamgeist machen aus Hitabis eine schlagfertige Mannschaft. Das beweisen ständig wachsende Mitarbeiterzahlen und eine sehr niedrige Fluktuation. Für Sie als Kunde bedeutet das ein hohes Maß an Leistungssicherheit und Kontinuität.`}</p>
      <p>{`Wir sind klein genug, um unsere Kunden noch persönlich zu kennen, aber groß genug, um innovative IT-Lösungen auch in kurzer Zeit umzusetzen sowie flexible Serviceangebote oder Notfalleinsätze garantieren zu können.`}</p>
      <p>{`Zusätzlich zählen engagierte Partnerschaften mit Systemanbietern ebenso wie die Mitarbeit in allen wichtigen branchenspezifischen Verbänden zu den Eckpfeilern unseres unternehmerischen Handelns.`}</p>
      <p>{`Neugierig geworden? Wir freuen uns darauf, Sie kennenzulernen. Nehmen Sie Kontakt mit uns auf.`}</p>
    </Columns>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      